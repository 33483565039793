<template>
	<div>
		<div class="left flex-direction-column">
			<div class="title flex-space-between newTitle">
				<span>{{ title }}</span>
			</div>
			<div class="service-left flex-direction-column" style="background-color: #FFFFFF">
				<div
					class="service-left-item flex-center"
					:style="currentIndex == index ? 'border:none;color: #FFFFFF;background:#EEEEEE;color: #212F82;font-weight: bold;' : ''"
					v-for="(item, index) in list"
					:key="index"
					@click="leftItemClick(item, index)"
				>
					<!--					<img v-if="currentIndex == index" src="@/assets/image/icon/08.png"/>-->
					<!--					<img v-else src="@/assets/image/icon/12.png"/>-->
					<span>{{ item.name }}</span>
				</div>
			</div>
		</div>
		<!-- 相关文章 -->
		<div class="relevant" v-if="isArticle">
			<div class="title title-vertical flex-space-between">相关文章</div>
			<div class="li flex-direction-column">
				<div class="li-item flex-space-between">
					<span></span>
					<p>莘庄基地特许赛第二站 男子110米栏决赛小将秦伟搏夺冠 谢文骏获亚军</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'

export default {
	props: {
		list: {
			type: Array,
			default() {
				return []
			}
		},
		title: {
			type: String,
			default() {
				return ''
			}
		},
		index: {
			type: Number,
			default() {
				return 0
			}
		},
		isArticle: {
			type: Boolean,
			default() {
				return false
			}
		}
	},
	data() {
		return {
			currentIndex: '',
			path: ''
		}
	},

	mounted() {
		this.path = this.$route.path
	},

	methods: {
		leftItemClick(item, index) {
			if (this.currentIndex == index) return
			this.currentIndex = index
			this.$emit('navMenuClick', index)
			this.$emit('navMenuLink', item)
			if (item.status == 0) {
				this.$router.replace({
					path: '/plist',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 1) {
				this.$router.replace({
					path: '/pudetail',
					params: {
						info: JSON.stringify({
							id: item.id,
							status: item.status
						})
					}
				});
			} else if (item.status == 2) {
				this.$router.replace({
					path: '/matchSignUp',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							name: item.name
						})
					}
				});
			} else if (item.status == 3) {
				this.$router.replace({
					path: '/integral',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 4) {
				this.$router.replace({
					path: '/matchSignUpTrain',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 5) {
				this.$router.replace({
					path: '/trainingVideos',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 6) {
				this.$router.replace({
					path: '/venueServices',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 7) {
				// token 没有就跳转
				this.$router.replace({
					name: 'achievementLogin',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
				// 有就跳转到另一个成绩页面
			} else {
				EventBus.$emit('aside', item)
			}
		}
	},
	watch: {
		index(val) {
			this.currentIndex = val
		}
	}
}
</script>

<style lang="less" scoped>
.left {
	padding: 0px 23px 0px 23px;
	width: 210px;
	//background: rgba(255, 255, 255);
	border-radius: 3px;
	// position: fixed;
	.newTitle {
		height: 60px;
		width: 100%;
		background: #212F82;
		font-weight: bold;
		font-size: 24px;
		color: #FFFFFF;
		line-height: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: 0;
	}

	.service-left {
		.service-left-item {
			//margin-top: 20px;
			width: 210px;
			padding: 14px 0;
			//border-radius: 3px;
			color: #333333;
			border-bottom: 1px solid #dddddd;
			line-height: 19px;
			cursor: pointer;

			img {
				width: 10px;
				height: 8px;
			}

			span {
				height: 25px;
				font-size: 16px;
				// font-weight: bold;
				line-height: 25px;
				//margin-left: 10px;
			}

			&:last-child {
				border: none;
			}
		}
	}
}

.relevant {
	background: #fff;
	margin-top: 20px;
	padding: 30px 23px 45px 23px;
	width: 234px;
	border-radius: 3px;

	.title-vertical {
		width: 237px;
		font-size: 28px;
		font-weight: bold;
		margin: 0;
		align-items: baseline;
	}

	.li {
		width: 100%;

		.li-item {
			margin-top: 20px;
			align-items: flex-start;
			cursor: pointer;

			span {
				width: 4px;
				height: 4px;
				background: #212F82;
				border-radius: 50%;
				margin-top: 10px;
			}

			p {
				flex: 1;
				height: 45px;
				font-size: 14px;
				line-height: 25px;
				color: #666666;
				margin-left: 5px;

				&:hover {
					color: #999999;
				}
			}
		}
	}
}
</style>
